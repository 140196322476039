import { KEYSTATES2 } from './keystates';

export default class Backgrounds {

	constructor(scrollContainer, gsap) {

		KEYSTATES2.forEach((keystate, i) => {
			const background = document.getElementById(`exhibit-background-${i + 1}`);
			const startProgress = keystate.progressAnimations[0].endProgress;
			const endProgress = keystate.progressAnimations[1].endProgress;

			gsap.to(`#exhibit-background-${i + 1}`, {
				y: () => `-${background.offsetHeight * 2 + window.innerHeight}px`,
				scrollTrigger: {
					scrub: 2,
					// Вычисление старта и окончания анимации согласно keystates
					start: () => (scrollContainer.scrollHeight - window.innerHeight) * startProgress + window.innerHeight / 2,
					end: () => (scrollContainer.scrollHeight - window.innerHeight) * endProgress + window.innerHeight * 2,
					// markers: true,
				}
			});

		});
	}

}

