import * as THREE from "three";
import { getProgressFromStart } from "./curve.js";

const KEYSTATES2 = [
  {
    exhibitId: 1, // Exhibit number
    exhibitName: "morgan", // Name of the exhibit
    // backgroundColor: "#EF8D46",				// Animated background color
    stickPosition: 5.173, // Point on the curve to stop the camera during auto transition
    progressAnimations: [
      {
        type: "lookAt", // Timeline type (span around the exhibit)
        startPosition: 0, // Point on the curve (spiral) of the beginning of the animation
        endPosition: 0, // Point on the curve (spiral) of the end of the animation
        startCameraTarget: [-0.552, 0, -0.584], // The coordinates the camera is looking at
        endCameraTarget: [-0.552, 0, -0.584], // The coordinates the camera is looking at
      },
      {
        type: "lookAt",
        startPosition: 0,
        endPosition: 37.382,
        startCameraTarget: [-0.552, 0, -0.584],
        endCameraTarget: [-0.552, 0, -0.584],
      },
    ],
  },
  {
    exhibitId: 2,
    exhibitName: "clown",
    // backgroundColor: "#65138C",
    stickPosition: 71.474,
    progressAnimations: [
      {
        type: "transition",
        startPosition: 37.382,
        endPosition: 61.3,
        startCameraTarget: [-0.552, 0, -0.584],
        endCameraTarget: [16.576, 9.578, 2.916],
      },
      {
        type: "lookAt",
        startPosition: 61.3,
        endPosition: 81.6425,
        startCameraTarget: [16.576, 9.578, 2.916],
        endCameraTarget: [16.576, 9.578, 2.916],
      },
    ],
  },
  {
    exhibitId: 3,
    exhibitName: "helmet",
    // backgroundColor: "#28B577",
    stickPosition: 115.4359,
    progressAnimations: [
      {
        type: "transition",
        startPosition: 81.6425,
        endPosition: 105.268,
        startCameraTarget: [16.576, 9.578, 2.916],
        endCameraTarget: [17.435, 17.29, 18.98],
      },
      {
        type: "lookAt",
        startPosition: 105.268,
        endPosition: 125.6,
        startCameraTarget: [17.435, 17.29, 18.98],
        endCameraTarget: [17.435, 17.29, 18.98],
      },
    ],
  },
  {
    exhibitId: 4,
    exhibitName: "rat",
    // backgroundColor: "#171717",
    stickPosition: 158.799,
    progressAnimations: [
      {
        type: "transition",
        startPosition: 125.6,
        endPosition: 149.528,
        startCameraTarget: [17.435, 17.29, 18.98],
        endCameraTarget: [2.566, 26.11, 25.76],
      },
      {
        type: "lookAt",
        startPosition: 149.528,
        endPosition: 168.069,
        startCameraTarget: [2.566, 26.11, 25.76],
        endCameraTarget: [2.566, 26.11, 25.76],
      },
    ],
  },
  {
    exhibitId: 5,
    exhibitName: "ttc-game",
    // backgroundColor: "#E53C43",
    stickPosition: 207.186,
    progressAnimations: [
      {
        type: "transition",
        startPosition: 168.069,
        endPosition: 197.257,
        startCameraTarget: [2.566, 26.11, 25.76],
        endCameraTarget: [-8.489, 38.03, 11.735],
      },
      {
        type: "lookAt",
        startPosition: 197.257,
        endPosition: 217.115,
        startCameraTarget: [-8.489, 38.03, 11.735],
        endCameraTarget: [-8.489, 38.03, 11.735],
      },
    ],
  },
  {
    exhibitId: 6,
    exhibitName: "ttc-map",
    // backgroundColor: "#1C96DA",
    stickPosition: 249.413,
    progressAnimations: [
      {
        type: "transition",
        startPosition: 217.115,
        endPosition: 240.142,
        startCameraTarget: [-8.489, 38.03, 11.735],
        endCameraTarget: [-0.513, 46.827, -0.612],
      },
      {
        type: "lookAt",
        startPosition: 240.142,
        endPosition: 258.684,
        startCameraTarget: [-0.513, 46.827, -0.612],
        endCameraTarget: [-0.513, 46.827, -0.612],
      },
    ],
  },
  {
    exhibitId: 7,
    exhibitName: "ttc-scheme",
    // backgroundColor: "#542FBD",
    stickPosition: 291.759,
    progressAnimations: [
      {
        type: "transition",
        startPosition: 258.684,
        endPosition: 284.343,
        startCameraTarget: [-0.513, 46.827, -0.612],
        endCameraTarget: [16.585, 57.453, 2.952],
      },
      {
        type: "lookAt",
        startPosition: 284.343,
        endPosition: 299,
        startCameraTarget: [16.585, 57.453, 2.952],
        endCameraTarget: [16.585, 57.453, 2.952],
      },
    ],
  },
  // {
  // 	exhibitId: 8,
  // 	exhibitName: 'tank',
  // 	// backgroundColor: "#1C96DA",
  // 	stickPosition: 332,
  // 	progressAnimations: [{
  // 		type: 'transition',
  // 		startPosition: 299,
  // 		endPosition: 327,
  // 		startCameraTarget: [16.585, 57.453, 2.952],
  // 		endCameraTarget: [17.5, 62, 19.83],
  // 	}, {
  // 		type: 'lookAt',
  // 		startPosition: 327,
  // 		endPosition: 345,
  // 		startCameraTarget: [17.5, 62, 19.83],
  // 		endCameraTarget: [17.5, 62, 19.83],
  // 	}],
  // },
  {
    exhibitId: 8,
    exhibitName: "bear",
    // backgroundColor: "#DA8C18",
    stickPosition: 375,
    progressAnimations: [
      {
        type: "transition",
        startPosition: 345,
        endPosition: 367,
        startCameraTarget: [17.5, 62, 19.83],
        endCameraTarget: [-1.681, 70.477, 30.926],
      },
      {
        type: "lookAt",
        startPosition: 345,
        endPosition: 389,
        startCameraTarget: [-1.681, 70.477, 30.926],
        endCameraTarget: [-1.681, 70.477, 30.926],
      },
    ],
  },
];

// Key points
const KEYSTATES = [
  // #1
  {
    exhibitId: 1, // Exhibit number
    startPosition: 0, // Point on a curve (spiral) from the start
    startFromPosition: 5.173, // Point on a curve (spiral) from the start
    type: "lookAt", // Timeline type (survey around)
    cameraTarget: [-0.552, 0, -0.584], // The coordinates the camera is looking at
  },
  {
    exhibitId: 1,
    startPosition: 37.382,
    type: "lookAt",
    cameraTarget: [-0.552, 0, -0.584],
  },

  // #2
  {
    exhibitId: 2,
    startPosition: 61.3,
    startFromPosition: 71.474,
    type: "transition",
    cameraTarget: [16.576, 9.578, 2.916],
  },
  {
    exhibitId: 2,
    startPosition: 81.6425,
    type: "lookAt",
    cameraTarget: [16.576, 9.578, 2.916],
  },

  // #3
  {
    exhibitId: 3,
    startPosition: 105.268,
    startFromPosition: 115.4359,
    type: "transition",
    cameraTarget: [17.435, 17.29, 18.98],
  },
  {
    exhibitId: 3,
    startPosition: 125.6,
    type: "lookAt",
    cameraTarget: [17.435, 17.29, 18.98],
  },

  //#4
  {
    exhibitId: 4,
    startPosition: 149.528,
    startFromPosition: 158.799,
    type: "transition",
    cameraTarget: [2.566, 26.11, 25.76],
  },
  {
    exhibitId: 4,
    startPosition: 168.069,
    type: "lookAt",
    cameraTarget: [2.566, 26.11, 25.76],
  },

  // #5
  {
    exhibitId: 5,
    startPosition: 197.257,
    startFromPosition: 207.186,
    type: "transition",
    cameraTarget: [-8.489, 38.03, 11.735],
  },
  {
    exhibitId: 5,
    startPosition: 217.115,
    type: "lookAt",
    cameraTarget: [-8.489, 38.03, 11.735],
  },

  // #6
  {
    exhibitId: 6,
    startPosition: 240.142,
    startFromPosition: 249.413,
    type: "transition",
    cameraTarget: [-0.513, 46.827, -0.612],
  },
  {
    exhibitId: 6,
    startPosition: 258.684,
    type: "lookAt",
    cameraTarget: [-0.513, 46.827, -0.612],
  },

  // #7
  {
    exhibitId: 7,
    startPosition: 284.343,
    startFromPosition: 291.759,
    type: "transition",
    cameraTarget: [16.585, 57.453, 2.952],
  },
  {
    exhibitId: 7,
    startPosition: 299,
    type: "lookAt",
    cameraTarget: [16.585, 57.453, 2.952],
  },

  // #8
  // {
  //   exhibitId: 8,
  //   startPosition: 327,
  //   startFromPosition: 332,
  //   type: "transition",
  //   cameraTarget: [17.5, 62, 19.83],
  // },
  // {
  //   exhibitId: 8,
  //   startPosition: 345,
  //   type: "lookAt",
  //   cameraTarget: [17.5, 62, 19.83],
  // },

  // #9
  {
    exhibitId: 8,
    startPosition: 367,
    startFromPosition: 375,
    type: "transition",
    cameraTarget: [-1.681, 70.477, 30.926],
  },
  {
    exhibitId: 8,
    startPosition: 389,
    type: "lookAt",
    cameraTarget: [-1.681, 70.477, 30.926],
  },
];

KEYSTATES.map((keystate) => {
  keystate.cameraTarget = new THREE.Vector3(...keystate.cameraTarget);
  keystate.progress = getProgressFromStart(keystate.startPosition);
  if (keystate.startFromPosition) {
    keystate.progressFrom = getProgressFromStart(keystate.startFromPosition);
  }
});

KEYSTATES2.map((keystate) => {
  keystate.stickProgress = getProgressFromStart(keystate.stickPosition);
  keystate.progressAnimations.forEach((animation) => {
    animation.startProgress = getProgressFromStart(animation.startPosition);
    animation.endProgress = getProgressFromStart(animation.endPosition);
    animation.startCameraTarget = new THREE.Vector3(
      ...animation.startCameraTarget
    );
    animation.endCameraTarget = new THREE.Vector3(...animation.endCameraTarget);
  });
});

// Key states in which the camera flies around the exhibit
const lookAtKeyStates = KEYSTATES.filter((keyState, i) =>
  i % 2 ? false : true
);

export { KEYSTATES as keystates, KEYSTATES2, lookAtKeyStates };
