import { KEYSTATES2 } from './keystates';

export default class BackgroundColor {
	constructor(gsap) {

		const MAIN_BG_COLOR = '#1a1a1a';
		const backgroundKeystates = [];
		
		KEYSTATES2.forEach(keystate => {
			keystate.progressAnimations.forEach(animation => {
				backgroundKeystates.push({
					progress: animation.endProgress,
					backgroundColor: keystate.backgroundColor
				});
			});
		});

		let tl = createTimeline();
		function createTimeline() {

			// Таймлайн для смены цвета фона
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: "body",
					scrub: 1,
					ease: "power3.ease",
					end: () => `+=${document.body.offsetHeight}`,
					// markers: true
				}
			});

			// Секция start
			tl.to(`body`, {
				duration: 0.05,
				backgroundColor: MAIN_BG_COLOR,
				ease: "none"
			}, 0)

			// -0.029 для первого перехода!
			// Старт анмации будет смещен влево по таймлайну
			let lastProgress = -0.029;
			backgroundKeystates.forEach(keystate => {
				tl.to('body', {
					duration: keystate.progress - lastProgress,
					backgroundColor: keystate.backgroundColor,
					ease: "none"
				});
				lastProgress = keystate.progress;
			});

			// Секция contact
			tl.to('body', {
				duration: 0.05,
				backgroundColor: MAIN_BG_COLOR,
				ease: "none"
			});

			return tl
		}

		window.addEventListener("goToStart:start", () => {
			tl.clear().kill();
		});

		window.addEventListener("goToStart:end", () => {
			tl = createTimeline();
		});

	}
}