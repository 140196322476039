import { UnsignedByteType, PMREMGenerator } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

export function loadHDR(path, renderer, scene, manager) {
	return new Promise((resolve, reject) => {
		const loader = new RGBELoader(manager);
		loader.setDataType(UnsignedByteType);
		loader.load(
			path,
			texture => {
				const pmremGenerator = new PMREMGenerator(renderer);
				pmremGenerator.compileEquirectangularShader();
				// const envMap = pmremGenerator.fromEquirectangular(texture).texture;
				// this.scene.background = envMap;
				scene.environment = pmremGenerator.fromEquirectangular(texture).texture;
				texture.dispose();
				pmremGenerator.dispose();

				resolve(texture);
			},
			// called while loading is progressing
			function (xhr) {
				console.log((xhr.loaded / xhr.total * 100) + '% HDR loaded');
			},
			// called when loading has errors
			function (error) {
				console.log('loadHDR()', error);
				reject(error);
			});
	});
}
