import * as THREE from 'three';
import { keystates, KEYSTATES2 } from './keystates.js';
import { curve, getLengthFromStartAt } from './curve.js';


export default class CameraAnimation {
    constructor({ scene, camera, TIMELINE, slider, devInfo }) {

        //Сamera preset
        camera.layers.disableAll();
        camera.layers.enable(0);
        camera.layers.enable(1);
        camera.position.copy(curve.getPointAt(0));
        camera.lookAt(keystates[0].cameraTarget);
        camera.activeTarget = new THREE.Object3D(); scene.add(camera.activeTarget);
        camera.activeTarget.name = 'camera_target';
        camera.activeTarget.position.copy(keystates[0].cameraTarget);
        const lerpedQuaternion = camera.quaternion.clone(); //Сглаженное положение камеры в повороте

        // DEBUG
        camera.timelineProgress = 0;
        camera.quaternionstr = '';

        // const keystates2 = [];
        // KEYSTATES2.forEach(exhibit => {
        //     exhibit.progressAnimations.forEach(animation => keystates2.push(animation));
        // });

        // Таймлайны переходов камеры
        for (let i = 0; i < keystates.length - 1; i++) {

            const transition = { progress: 0 };
            const QUATERNION_FROM = getPositionalQuaternion(keystates[i].progress, keystates[i].cameraTarget);
            const QUATERNION_TO = getPositionalQuaternion(keystates[i + 1].progress, keystates[i + 1].cameraTarget);

            TIMELINE
                .to(transition, {
                    progress: 1,
                    duration: keystates[i + 1].progress - keystates[i].progress,

                    onReverseComplete: () => {
                        // При обратном переходе на анимацию 'transition'
                        if (keystates[i].type === 'transition') {
                            // Показать слой с приближающимся экспонатом
                            camera.layers.enable(keystates[i].exhibitId - 1);
                            // Выставить свет на приближающийся экспонат
                            camera.activeTarget.position.copy(keystates[i - 1].cameraTarget);
                            // DEBUG
                            // console.log('[light target] onReverseComplete >>', keystates[i - 1].exhibitId);
                            // debugCamera.layers.enable(keystates[i].exhibitId - 1);
                            // console.log('(onReverseComplete 1) LAYER %d ENABLED', keystates[i].exhibitId - 1);
                        }
                        if (keystates[i].type === 'lookAt') {
                            // При обратном переходе на анимацию 'lookAt' спрятать слой с отдаляющимся экспонатом
                            camera.layers.disable(keystates[i].exhibitId + 1);
                            // DEBUG
                            // debugCamera.layers.disable(keystates[i].exhibitId + 1);
                            // console.log('(onReverseComplete 2) LAYER %d DISABLED', keystates[i].exhibitId + 1);
                        }
                    },
                    onStart: () => {
                        // При переходе на анимацию 'lookAt' 
                        if (keystates[i + 1].type === 'lookAt') {
                            if (keystates[i + 1].exhibitId - 1) { // Если слой не 0
                                // Cкрыть слой с отдаляющимся экспонатом
                                camera.layers.disable(keystates[i + 1].exhibitId - 1);
                                // Выставить свет на приближающийся экспонат
                                camera.activeTarget.position.copy(keystates[i + 1].cameraTarget);
                                // DEBUG
                                // console.log('[light target] onStart >>', keystates[i + 1].exhibitId);
                                // debugCamera.layers.disable(keystates[i + 1].exhibitId - 1);
                                // console.log('(onStart 1) LAYER %d DISABLED', keystates[i + 1].exhibitId - 1);
                            }
                        }
                        // При переходе на анимацию 'transition'
                        if (keystates[i + 1].type === 'transition') {
                            // Показать слой с приближающимся экспонатом
                            camera.layers.enable(keystates[i + 1].exhibitId);
                            // DEBUG
                            // debugCamera.layers.enable(keystates[i + 1].exhibitId);
                            // console.log('(onStart 2) LAYER %d ENABLED', keystates[i + 1].exhibitId);
                        }
                    },
                    onUpdate: () => {
                        // DEBUG
                        // camera.quaternionstr = `${camera.quaternion.x}, ${camera.quaternion.y}, ${camera.quaternion.z}, ${camera.quaternion.w}`;
                        // devInfo.timelineProgress = TIMELINE.progress();
                        // devInfo.timelineProgressLength = getLengthFromStartAt(TIMELINE.progress());
                        // camera.timelineProgress = TIMELINE.progress();

                        if (keystates[i + 1].type === 'transition') {
                            // Обновляет поворот камеры (используется на каждый тик скролла) 
                            lerpedQuaternion.slerpQuaternions(QUATERNION_FROM, QUATERNION_TO, transition.progress);
                        } else {
                            lerpedQuaternion.copy(getPositionalQuaternion(TIMELINE.progress(), keystates[i].cameraTarget));
                            // Обновить номер активного слайда для слайдера если он не равен номеру текущего экспоната
                            slider.activeSlide = slider.activeSlide !== keystates[i + 1].exhibitId ? keystates[i + 1].exhibitId : slider.activeSlide;
                        }
                        // console.log(`Q${i} to Q${i + 1}`, transition.progress);
                    }

                }, keystates[i].progress);
        }

        // Нахождение кватерниона камеры
        function getPositionalQuaternion(progress, target) {
            const originalCameraPosition = new THREE.Vector3(); originalCameraPosition.copy(camera.position);
            const originalCameraQuaternion = new THREE.Quaternion(); originalCameraQuaternion.copy(camera.quaternion);
            const positionalQuaternion = new THREE.Quaternion();

            // Нахождение позиции и кватерниона для камеры
            camera.position.copy(curve.getPointAt(progress));
            camera.lookAt(target);
            positionalQuaternion.copy(camera.quaternion);

            // Возврат камеры к начальным значениям
            camera.quaternion.copy(originalCameraQuaternion);
            camera.position.copy(originalCameraPosition);
            return positionalQuaternion;
        }

        // Camera animation tick
        camera.tick = () => {
            camera.position.lerp(curve.getPointAt(TIMELINE.progress()), 0.3); // LERP положения камеры
            camera.quaternion.slerp(lerpedQuaternion, 0.3); // LERP поворота камеры
        }
    }
}