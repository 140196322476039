export default class CanvasContainer {

	constructor(ScrollTrigger) {
		ScrollTrigger.create({
			trigger: '#scroll-container',
			pin: '#canvas-container',
			end: 'bottom bottom',
			// markers: true
		});
	}

}