import { KEYSTATES2 } from './keystates';

export default class SectionContact {
	constructor(ScrollTrigger, scrollTo) {

		// ТОЛЬКО ДЛЯ DESKTOP
		if (!window.isMobile(window.navigator).any) {

			// ПЕРЕХОД (вниз) к #section-contact
			ScrollTrigger.create({
				trigger: '#section-contact',
				onEnter: () => {
					scrollTo.goToSection('#section-contact', 1);
					setTimeout(() => {
						history.replaceState(null, null, ' ');
					}, 1300);
				},
			});

			// ПЕРЕХОД (вверх) к #section-gallery (последнему экспонату)
			const lastKeystate = KEYSTATES2[KEYSTATES2.length - 1];
			ScrollTrigger.create({
				trigger: '#section-gallery',
				onEnterBack: () => {
					scrollTo.goToProgress(lastKeystate.stickProgress);
					history.replaceState(null, null, `#exhibit-desc-${lastKeystate.exhibitId}`);
				}
			});

		}

		// КНОПКА (вверх) перехода к #section-start
		document.getElementById('scroll-to-section-start')
			.onclick = () => scrollTo.goToStart();
	}
}