import * as THREE from 'three';

function _renderer(options) {
	return new THREE.WebGLRenderer(options || {
		powerPreference: "high-performance",
		antialias: true,
		alpha: true, // Transparent canvas
	});
}

const _scene = () => {
	return new THREE.Scene();
}

const _camera = (width, height) => {
	const FOV = 45;
	const ASPECT = width / height;
	const NEAR = 0.1;
	const FAR = 500;
	return new THREE.PerspectiveCamera(FOV, ASPECT, NEAR, FAR);
}

const _manager = () => {
	return new THREE.LoadingManager();
}

// Экспорт отдельных модулей без инициализации
export {
	_renderer as newRenderer,
	_scene as newScene,
	_camera as newCamera,
	_manager as newManager
}

// Экспорт сетапа
export function setup(container) {
	const aspectWidth = container.offsetWidth;
	const aspectHeight = container.offsetHeight;

	const renderer = _renderer();
	const scene = _scene();
	const camera = _camera(aspectWidth, aspectHeight);
	const manager = _manager();

	renderer.setSize(aspectWidth, aspectHeight);

	renderer.outputEncoding = THREE.sRGBEncoding;
	// renderer.toneMapping = THREE.ACESFilmicToneMapping;
	renderer.shadowMap.enabled = true;
	renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
	renderer.setPixelRatio(Number(window.devicePixelRatio) / 1.5);
	// renderer.toneMappingExposure = 0.4;
	renderer.setPixelRatio(0.7);

	if (window.isMobile(window.navigator).any) {
		renderer.setPixelRatio(1.5);
		renderer.shadowMap.enabled = false;
	}

	container.appendChild(renderer.domElement);

	return {
		renderer,
		scene,
		camera,
		manager
	}
}
