<template>
	<Main />
</template>

<script>
import Main from "@/components/Main.vue";

export default {
	name: "App",
	components: {
		Main,
	},
	mounted() {
		// Убирать псевдокласс :focus с кнопок
		document.addEventListener("click", () => document.activeElement.blur());
	},
};
</script>