<template>
	<slot>
		<SectionStart />
		<SectionGallery />
		<SectionContact />
	</slot>
</template>

<script>
import SectionStart from "@/components/SectionStart.vue";
import SectionGallery from "@/components/SectionGallery.vue";
import SectionContact from "@/components/SectionContact.vue";

export default {
	name: "Main",
	components: {
		SectionStart,
		SectionGallery,
		SectionContact,
	},
};
</script>
