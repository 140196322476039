export default class SectionStart {
	constructor(ScrollTrigger, scrollTo) {

		// ТОЛЬКО ДЛЯ DESKTOP
		if (!window.isMobile(window.navigator).any) {

			// ПЕРЕХОД (вниз) к #section-gallery
			ScrollTrigger.create({
				onEnter: () => {
					scrollTo.goToSection('#section-gallery', 1)
					history.replaceState(null, null, '#exhibit-desc-1');
				},
			});

			// ПЕРЕХОД (вверх) к #section-start
			ScrollTrigger.create({
				trigger: '#section-start',
				onEnterBack: () => {
					scrollTo.goToSection('#section-start', -1)
					setTimeout(() => {
						history.replaceState(null, null, ' ');
					}, 300);
				},
				// markers: true
			});

		}

		// КНОПКА (вниз) перехода к #section-gallery
		document.getElementById('scroll-to-section-gallery').onclick = () => {
			scrollTo.goToSection('#section-gallery', 1)
			history.replaceState(null, null, '#exhibit-desc-1');
		}

		// ИКОНКИ "мышь/палец"
		window.addEventListener("preloader:hidden", () => {
			let gap = window.matchMedia("(max-width: 1024px)").matches ? 50 : 0;
			ScrollTrigger.create({
				trigger: 'body',
				pin: '#scroll-mouse-icon',
				start: `top+=${gap} top`,
				end: `100%-=${window.innerHeight + gap}px bottom`,
				onRefresh: (e) => {
					let gap = window.matchMedia("(max-width: 1024px)").matches ? 50 : 0;
					e.vars.start = `top+=${gap}px top`;
					e.vars.end = `100%-=${window.innerHeight + gap}px bottom`;
				},
				// markers: true
			});
		});

	}
}