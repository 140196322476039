import { KEYSTATES2 } from './keystates';

export default class Slider {
	constructor(scrollTo) {
		this._activeSlide = 1;

		// Слушает события переключения слайдов из Vue
		// и выполняет переход на нужную позицию
		// затем сменяет номер активного слайда
		window.addEventListener("slider:slideChange", (e) => {
			scrollTo
				.goToProgress(KEYSTATES2[e.detail.number - 1].stickProgress)
				.then(() => this.activeSlide = e.detail.number);
		});
	}

	set activeSlide(number) {
		if (this.activeSlide === number) return;
		// Сообщает о смене номера слайда
		// для отрисовки буллита во Vue
		window.dispatchEvent(new CustomEvent("slider:slideNumberChanged", { detail: { number } }));
		this._activeSlide = number;
	}

	get activeSlide() {
		return this._activeSlide;
	}
}