import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

import debounce from "lodash.debounce";

//DEBUG
// import { lookAtKeyStates } from './scroller-modules/keystates.js';

const MODELS = [
  // {
  // 	name: "cubes",
  // 	path: "/models/gltf/",
  // },
  // {
  // 	name: "spheres_spiral",
  // 	path: "/models/gltf/",
  // },
  {
    name: "morgan",
    path: "./gltf/exhibits/",
    position: [0, 0, -0.5],
    positionMobile: [-0.65, 0.5, 0.3],
    rotation: [0, 3, 0],
    layer: 1,
  },
  {
    name: "clown",
    path: "./gltf/exhibits/",
    position: [16.41, 8.86, 1.43],
    positionMobile: [16.41, 9.5, 1.43],
    rotation: [3.141, -0.078, 3.141],
    layer: 2,
  },
  {
    name: "helmet",
    path: "./gltf/exhibits/",
    position: [17.39, 17.85, 19.14],
    positionMobile: [17.39, 17.6, 19.14],
    rotation: [-2.842, 1.012, 2.891],
    layer: 3,
  },
  {
    name: "rat",
    path: "./gltf/exhibits/",
    position: [3.09, 24.98, 26.66],
    positionMobile: [3.09, 26, 26.66],
    rotation: [0, 0.7, 0],
    layer: 4,
  },
  {
    name: "ttc_game",
    path: "./gltf/exhibits/",
    position: [-8.62, 37.31, 11.44],
    positionMobile: [-8.62, 37.55, 11.44],
    rotation: [-3.142, -2.56, -3.142],
    layer: 5,
  },
  {
    name: "ttc_map",
    path: "./gltf/exhibits/",
    position: [-1.79, 45.6, -0.94],
    positionMobile: [-1.79, 46.6, -0.94],
    rotation: [-3.132, -1.41, -3.132],
    layer: 6,
  },
  {
    name: "ttc_scheme",
    path: "./gltf/exhibits/",
    position: [16.95, 57.99, 1.53],
    rotation: [0, -3.48, 0],
    layer: 7,
  },
  // {
  //   name: "tank",
  //   path: "./gltf/exhibits/",
  //   position: [17.52, 58.59, 19.88],
  //   positionMobile: [17.52, 60.5, 19.88],
  //   rotation: [0, 1.41, 0],
  //   layer: 8,
  // },
  {
    name: "bear",
    path: "./gltf/exhibits/",
    position: [-1.681, 69.8, 30.426],
    positionMobile: [-1.681, 70.7, 30.426],
    rotation: [0, -1.6, 0],
    layer: 8,
  },
].map((model) => {
  model.position = model.position
    ? new THREE.Vector3(...model.position)
    : undefined;
  model.rotation = model.rotation
    ? new THREE.Euler(...model.rotation)
    : undefined;
  model.positionMobile = model.positionMobile
    ? new THREE.Vector3(...model.positionMobile)
    : undefined;
  return model;
});

export default class ModelLoader {
  constructor(scene, manager, preloader) {
    const dracoLoader = new DRACOLoader();

    dracoLoader.setDecoderPath("/draco/");
    // dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.preload();

    this.scene = scene;
    this.manager = manager;
    this.models = MODELS;
    this.preloader = preloader;
    this.preloaderStep = 100 / this.models.length;
    this.promises = [];
    this.mixers = [];
    this.loader = new GLTFLoader(this.manager);
    this.loader.setDRACOLoader(dracoLoader);

    this.models.forEach((model) => {
      this.promises.push(() => {
        return new Promise((resolve, reject) => {
          this.loader.load(
            `${model.path}${model.name}.gltf`,
            (gltf) => {
              try {
                const mixer = new THREE.AnimationMixer(gltf.scene);
                const action = mixer.clipAction(gltf.animations[0]);

                mixer.model_name = model.name;
                action.play();
                this.mixers.push(mixer);
              } catch (error) {
                console.log("Mixer animation error", error);
              }

              gltf.scene.traverse((child) => {
                if (child.isMesh) {
                  if (!!window.isMobile.any === false) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                  }
                  child.layers.set(model.layer);
                }
              });

              if (model.rotation) {
                gltf.scene.setRotationFromEuler(model.rotation);
              }

              if (model.position) {
                const onResize = debounce((modelGltf) => {
                  if (modelGltf) {
                    modelGltf.scene.scale.set(0.8, 0.8, 0.8);
                    modelGltf.scene.position.copy(model.position);

                    if (window.innerWidth <= 1024) {
                      modelGltf.scene.scale.set(0.4, 0.4, 0.4);

                      if (model.positionMobile) {
                        modelGltf.scene.position.copy(model.positionMobile);
                      }
                    }
                  }
                }, 300);

                window.addEventListener(
                  "resize",
                  onResize.bind(this, gltf),
                  false
                );

                onResize(gltf);
              }

              scene.add(gltf.scene);
              // console.log(gltf.scene.name, new Date().getTime());
              this.preloader.progress += this.preloaderStep;
              requestAnimationFrame(() => setTimeout(resolve, 100));
            },
            (progress) => {
              progress; /*console.log('Loading progress...', progress)*/
            },
            (error) => {
              reject();
              console.error("loader.js >>", error);
            }
          );
        });
      });
    });

    // DEBUG
    // lookAtKeyStates.forEach((lookAtKeyState, i) => {
    // 	const octahedron = new THREE.Mesh(new THREE.OctahedronBufferGeometry(1.3), new THREE.MeshStandardMaterial({ color: 0x22ff22 }));
    // 	octahedron.position.copy(lookAtKeyState.cameraTarget);
    // 	octahedron.name = `o_target_${i + 1}`;
    // 	octahedron.layers.set(i + 1);
    // 	scene.add(octahedron);

    // 	octahedron.visible = false;
    // });
  }

  load() {
    for (const iterator of this.promises) {
      iterator();
    }
    return true;
  }

  loadModelByNumber(modelNumber) {
    if (modelNumber > MODELS.length) return;
    return new Promise((resolve, reject) => {
      const model = this.models[modelNumber - 1];

      this.loader.load(
        `${model.path}${model.name}.gltf`,
        (gltf) => {
          try {
            const mixer = new THREE.AnimationMixer(gltf.scene);
            mixer.model_name = model.name;
            const action = mixer.clipAction(gltf.animations[0]);
            action.play();
            this.mixers.push(mixer);
          } catch (error) {
            console.log("Mixer animation error", error);
          }

          gltf.scene.traverse((child) => {
            if (child.isMesh) {
              if (!!window.isMobile.any === false) {
                child.castShadow = true;
                child.receiveShadow = true;
              }
              // child.layers.set(model.layer);
            }
          });

          if (model.position) gltf.scene.position.copy(model.position);
          if (model.rotation) gltf.scene.setRotationFromEuler(model.rotation);

          if (window.isMobile.any) {
            gltf.scene.scale.set(0.7, 0.7, 0.7);
            if (model.positionMobile) {
              gltf.scene.position.copy(model.positionMobile);
            }
          }

          this.scene.add(gltf.scene);
          this.preloader.progress += 100;

          resolve();
        },
        (progress) => {
          progress; /*console.log('Loading progress...', progress)*/
        },
        (error) => {
          reject();
          console.error("model-loader.js >>", error);
        }
      );
    });
  }
}
