export default class Preloader {
	constructor() {
		this.preloader = document.getElementById("preloader");
		this.preloaderContainer = document.getElementById("preloader-container");
		this.preloaderLine = document.getElementById("preloader-line");
		this.preloaderPrecent = document.getElementById("preloader-percent");
		this._progress = 0;
		this.percent = 0;

		document.addEventListener("DOMContentLoaded", () => {
			this.preloaderContainer.classList.add('visible');
		});

		this.preloaderEventHide = new CustomEvent("preloader:hide");
		this.preloaderEventHidden = new CustomEvent("preloader:hidden");

		const animate = () => {
			if (this.percent < 99.8) {
				this.percent += (this.progress - this.percent) * 0.2;
				this.preloaderLine.style.height = `${this.percent}vh`;
				this.preloaderPrecent.innerText = `${(~~this.percent > 98 ? 100 : ~~this.percent)}%`;
				requestAnimationFrame(animate)
			}
		}
		animate();
	}

	hide() {
		document.body.style.overflowY = 'auto';
		window.dispatchEvent(new Event('resize'));
		this.preloader.style.opacity = 0;
		this.preloader.addEventListener("transitionend", () => {
			window.dispatchEvent(this.preloaderEventHide);
			setTimeout(() => {
				this.preloader.classList.add('hidden');
				window.dispatchEvent(this.preloaderEventHidden);
			}, 100);
		}, false);
	}

	checkProgress() {
		if (this.progress === 100) {
			setTimeout(() => {
				this.hide();
			}, 1000);
		}
	}

	set progress(percent) {
		this._progress = percent <= 100 ? percent : 100;
		this.checkProgress();
	}

	get progress() {
		return this._progress;
	}
}