import { createI18n } from "vue3-i18n"
import { ru, en } from './data/locales.json'

// const [lang, locale] = (
// 	(
// 		(
// 			navigator.userLanguage || navigator.language
// 		).replace(
// 			'-', '_'
// 		)
// 	).toLowerCase()
// ).split('_');

const i18n = createI18n({
	locale: window.location.hostname === 'ar.egorovagency.com' ? 'en' : 'ru' /*lang || locale*/,
	messages: { en, ru },
});

document.getElementsByTagName("html")[0].lang = window.location.hostname === 'ar.egorovagency.com' ? 'en' : 'ru';

export default i18n