<template>
	<div id="mobile-header" class="mobile-header">
		<div class="mobile-header__control">
			<button
				@click="$emit('openInfo', index)"
				class="btn btn_white btn_icon svg-icon js-open-info"
				type="button"
				aria-label="Open information"
			>
				<svg role="img" aria-hidden="true" width="26" height="26">
					<use xlink:href="#svg-icon-info"></use>
				</svg>
			</button>
		</div>

		<div class="mobile-header__title section__title">
			<h2>{{ title }}</h2>
		</div>

		<div class="mobile-header__control">
			<button
				@click="$emit('openVideo', index)"
				class="btn btn_white btn_icon btn_play svg-icon js-btn-play-video"
				type="button"
				aria-label="View video"
				@mouseover="$emit('playVideoButtonMouseOver')"
			>
				<svg role="img" aria-hidden="true" width="18" height="18">
					<use xlink:href="#svg-icon-play"></use>
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	props: ["videos"],
	data() {
		return {
			index: 1,
			title: "",
		};
	},
	mounted() {
		window.addEventListener("slider:slideNumberChanged", (e) => {
			this.index = e.detail.number;
			this.title = this.$t(`section_gallery.title_${e.detail.number}`);
		});

		this.title = this.$t(`section_gallery.title_${this.index}`);
	},
};
</script>

<style lang="scss" scoped>
.mobile-header {
	@media (min-width: 1025px) {
		display: none;
	}

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 10px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	height: 70px;
	transition: opacity ease-in-out 0.3s, visibility ease-in-out 0.3s;

	&__controls {
		transform: translateY(0);
		transition: transform ease-in-out 0.3s;
		flex: 0 0 50px;
		max-width: 50px;
	}

	&__title {
		transform: translateY(0);
		transition: transform ease-in-out 0.3s;
		flex: 0 0 100%;
		max-width: calc(100% - 130px);
		font-size: 30px;
		line-height: 1;
		text-align: center;
		letter-spacing: -0.03em;
	}
}

.modal-opened {
	@media (max-width: 1024px) {
		.mobile-header {
			opacity: 0;
			visibility: hidden;
			& > * {
				transition: transform ease-in-out 0.3s;
				transform: translateY(-70px);
			}
		}
	}
}
</style>