export default class ScrollTo {
	constructor(scrollContainer, gsap) {
		this.scrollContainer = scrollContainer;
		this.gsap = gsap;
	}

	goToProgress(progress) {
		return new Promise(resolve => {

			const startPosition = document.querySelector('.start').offsetHeight;
			const position = startPosition + (this.scrollContainer.offsetHeight - window.innerHeight) * progress;
			if (window.scrollAnimation || typeof position !== 'number') return;
			window.scrollAnimation = true;

			this.gsap.to(window, {
				scrollTo: {
					y: position,
					autoKill: false,
				},
				ease: "power4.ease",
				duration: 1.5,
				onComplete: () => { window.scrollAnimation = false; resolve(); }
			});
		});

	}

	goToSection(section, gap) {
		return new Promise((resolve) => {
			if (window.scrollAnimation) return;

			window.scrollAnimation = true;
			this.gsap.to(window, {
				scrollTo: {
					y: section,
					offsetY: gap < 0 ? 2 : -2,
					autoKill: false
				},
				duration: 1.5,
				ease: "power3.out",
				onComplete: () => {
					window.scrollAnimation = false
					resolve();
				}
			});

		});
	}

	goToStart() {
		if (window.scrollAnimation) return;
		window.scrollAnimation = true;
		window.dispatchEvent(new CustomEvent('goToStart:start'));
		document.querySelector('#section-gallery').style.display = "none";

		setTimeout(() => {
			document.documentElement.scrollTop = window.innerHeight / 5;

			setTimeout(() => {
				this.gsap.to(window, {
					scrollTo: 0,
					duration: 0.5,
					onComplete: () => {
						document.querySelector('#section-gallery').removeAttribute('style');
						setTimeout(() => {
							window.scrollAnimation = false;
							window.dispatchEvent(new CustomEvent('goToStart:end'));
							setTimeout(() => {
								history.replaceState(null, null, ' ');
							}, 500);
						}, 500);
					}
				});
			}, 1);

		}, 1);
	}

}