//#region Imports
// import DevTools from "./dev/devtools";
// import { curve, curveLength } from './scroller-modules/curve.js';

import * as THREE from "three";
const debounce = require("lodash.debounce");

import Preloader from "./preloader";
import ModelLoader from "./model-loader";
import Scroller from "./scroller";

import { setup } from "./setup";
import { loadHDR } from "./hdr-loader";

import { keystates } from "./scroller-modules/keystates";

//#endregion

export default class GalleryScroller {
  constructor() {
    const preloader = new Preloader();
    const container = document.getElementById("canvas-container");
    const { renderer, scene, camera, manager } = setup(container);
    const modelLoader = new ModelLoader(scene, manager, preloader);
    const clock = new THREE.Clock();

    const devInfo = {
      // timelineLength: curveLength,
      timelineProgress: 0,
      timelineProgressLength: 0,
    };

    // window.devInfo = devInfo;

    let isVideoModalOpened = false;

    window.addEventListener("videoModal:opened", () => {
      isVideoModalOpened = true;
    });
    window.addEventListener("videoModal:close", () => {
      isVideoModalOpened = false;
    });
    scene.add(camera);

    // const devtools = new DevTools({ container, renderer, scene, camera, devInfo }); // Тулзы для отладки

    (async () => {
      await loadHDR("./hdr/studio_xxs.hdr", renderer, scene, manager);
      await modelLoader.load();
      camera.layers.enableAll();
      renderer.render(scene, camera);

      //TODO
      // Prerender each model

      camera.position.set(7, 0, 5);
      camera.lookAt(0, 1000, 0);
      renderer.render(scene, camera);

      setTimeout(() => {
        keystates.forEach((state) => {
          camera.lookAt(state.cameraTarget);
          camera.position.set(state.cameraTarget.x, state.cameraTarget.y, 5);
          renderer.render(scene, camera);
        });
      });

      setTimeout(() => {
        document
          .getElementById("section-gallery")
          .classList.remove("prerender");
        new Scroller({ scene, camera, devInfo });
        animate();
      }, 100);

      // DEBUG — Линия спирали
      // const points = curve.getPoints(2000);
      // const line = new THREE.Line(
      // 	new THREE.BufferGeometry().setFromPoints(points),
      // 	new THREE.LineBasicMaterial({ color: 0xff0000 })
      // );
      // scene.add(line);

      //#region Light

      // scene.add(new THREE.AmbientLight(0xffffff, 0.4));

      const dirLight1 = new THREE.DirectionalLight(0xffffff, 1.4);
      dirLight1.castShadow = true;
      dirLight1.shadow.mapSize.set(1024, 1024);
      dirLight1.shadow.bias = -0.0002;
      scene.add(dirLight1);

      const dirLight2 = new THREE.DirectionalLight(0xfffffd, 0.5);
      dirLight2.position.set(0, 50, 15);
      dirLight2.castShadow = true;
      dirLight2.shadow.mapSize.set(512, 512);
      dirLight2.shadow.bias = -0.0002;

      const dirLight3 = new THREE.DirectionalLight(0xdfffff, 0.5);
      dirLight3.position.set(0, 50, -15);
      dirLight3.castShadow = true;
      dirLight3.shadow.mapSize.set(512, 512);
      dirLight3.shadow.bias = -0.0002;

      // Только для DESKTOP
      if (!window.isMobile(window.navigator).any) {
        dirLight2.intensity = 0.9;
        scene.add(dirLight2);
        scene.add(new THREE.AmbientLight(0xffffff, 0.3));

        // dirLight3.intensity = 0.7;
        // scene.add(dirLight3);
      }

      //#endregion Light

      //#region Создание массива векторов для спирали
      // let nSpheres = [];
      // let maxAmout = 53;
      // for (let index = 0; index < maxAmout; index++) {
      // 	if (scene.getObjectByName(`s${index + 1}`)) {
      // 		let sphere = scene.getObjectByName(`s${index + 1}`);
      // 		sphere.material.transparent = true;
      // 		sphere.material.opacity = 0.7;
      // 		nSpheres.push(sphere)
      // 	}
      // }
      // let nSpherePos = [];
      // nSpheres.forEach(sphere => {
      // 	nSpherePos.push([sphere.position.x, sphere.position.y, sphere.position.z]);
      // })
      // console.log(nSpherePos);

      // let nCubes = [];
      // for (let index = 0; index < 43; index++) {
      // 	if (scene.getObjectByName(`model${index + 1}`)) {
      // 		let cube = scene.getObjectByName(`model${index + 1}`);
      // 		cube.material.transparent = true;
      // 		cube.material.opacity = 0.7;
      // 		nCubes.push(cube)
      // 	}
      // }
      // let nCubesPos = [];
      // nCubes.forEach(cube => {
      // 	nCubesPos.push([cube.position.x, cube.position.y, cube.position.z]);
      // })
      // console.log(nCubesPos);
      //#endregion

      //#region Animate
      function animate() {
        if (!isVideoModalOpened) {
          const delta = clock.getDelta();

          for (let i = 0; i < modelLoader.mixers.length; i++) {
            modelLoader.mixers[i].update(delta + 0.005);
          }

          dirLight1.position.x = camera.position.x;
          dirLight1.position.y = camera.position.y + 10;
          dirLight1.position.z = camera.position.z;
          dirLight1.target = camera.activeTarget;

          dirLight2.position.x = camera.position.x / 2;
          dirLight2.position.y = camera.position.y + 15;
          dirLight2.position.z = -15 + camera.position.z / 2;
          dirLight2.target = camera.activeTarget;

          dirLight3.position.x = camera.position.x / 2;
          dirLight3.position.y = camera.position.y + 15;
          dirLight3.position.z = 15 + camera.position.z / 2;
          dirLight3.target = camera.activeTarget;

          camera.tick();

          renderer.render(scene, camera);
          // devtools.update();
        }

        requestAnimationFrame(animate);
      }

      //#endregion

      //#region onResize
      const onResize = debounce(() => {
        const aspectWidth = container.offsetWidth;
        const aspectHeight = container.offsetHeight;
        console.log("width", aspectWidth);
        console.log("height", aspectHeight);
        renderer.setSize(aspectWidth, aspectHeight);
        camera.aspect = aspectWidth / aspectHeight;
        camera.updateProjectionMatrix();
      }, 300);

      window.addEventListener(
        "resize",
        () => {
          onResize();
        },
        false
      );
      //#endregion
    })();
  }
}
