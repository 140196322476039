<template>
	<a
		class="contact-button"
		target="_blank"
		:href="
			$i18n.getLocale() === 'en'
				? 'https://webar.egorovagency.com/#contact-us'
				: 'https://webar.egorovagency.by/#contact-us'
		"
	>
		{{ $t("section_contact.button") }}
	</a>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.contact-button {
	position: absolute;
	top: calc(100vh - 95px);
	right: 144px;
	width: 214px;
	height: 50px;
	cursor: pointer;
	transition: border-color ease-in-out 0.3s;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	text-decoration: none;
	letter-spacing: 0.3em;
	text-transform: lowercase;
	pointer-events: all;
	color: #fff;
	border: 2px solid var(--color-white-03);
	background-color: rgba(0, 0, 0, 0.1);
	font-family: "Rex", Tahoma, sans-serif;
	font-weight: bold;
	line-height: 47px;
	transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s;

	@media screen and (max-width: 1024px) {
		position: absolute !important;
		right: -71px;
		bottom: 170px;
		top: unset;
		transform: rotate(270deg);
	}

	&:hover {
		border-color: #fff;
	}
}

.modal-opened .contact-button {
	@media (min-width: 1025px) {
		opacity: 0;
		transform: translateX(40%);
		pointer-events: none;
	}

	@media (max-width: 1024px) {
		opacity: 0;
		pointer-events: none;
		transform: translateX(20%) rotate(270deg);
	}
}
</style>