import { createApp } from 'vue'
import App from './App.vue'
import i18n from "./i18n";

import isMobile from 'ismobilejs';
window.isMobile = isMobile;

// style
import './assets/scss/index.scss'
// js
import GalleryScroller from './index'

createApp(App)
	.use(i18n)
	.mount('#app')

// Не запоминать положение скролла
if ('scrollRestoration' in history) {
	history.scrollRestoration = 'manual'
}

new GalleryScroller()

// Кастомные события
window.addEventListener('preloader:hide', () => { console.log('preloader:hide'); });
window.addEventListener('preloader:hidden', () => { console.log('preloader:hidden'); });
window.addEventListener("goToStart:start", () => { console.log('goToStart:start') });
window.addEventListener("goToStart:end", () => { console.log('goToStart:end') });
window.addEventListener("videoModal:opened", () => { console.log('videoModal:opened') });
window.addEventListener("videoModal:close", () => { console.log('videoModal:close') });
// Событие смены слайда (из Vue): Запускает анимацию перехода (для Scroller)
window.addEventListener("slider:slideChange", (e) => { console.log('slider:slideChange', e.detail.number); });
// Событие смены номера слайда (из Scroller): По факту перехода сообщает о сменившемся номере слайда (для Vue)
window.addEventListener("slider:slideNumberChanged", (e) => { console.log('slider:slideNumberChanged', e.detail.number); });