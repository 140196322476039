<template>
	<section id="section-start" class="section start opacity-0">
		<a class="section__logo svg-icon" href="/" aria-label="Egorov Agency">
			<svg role="img" aria-hidden="true" width="60" height="58">
				<use xlink:href="#svg-ea-logo"></use>
			</svg>
		</a>

		<div class="section__content">
			<div class="section__title section__title_start">
				<h1>{{ $t("section_start.title") }}</h1>
			</div>

			<div class="section__description">
				<p>
					{{ $t("section_start.text") }}
				</p>
			</div>
		</div>

		<button
			id="scroll-to-section-gallery"
			class="btn btn_red btn_default btn_rectangle_vertical btn_with-arrow_vertical"
			type="button"
		>
			<span class="btn__text">{{ $t("section_start.button") }}</span>
			<span class="btn__icon svg-icon" aria-hidden="true">
				<svg role="img" aria-hidden="true" width="64" height="18">
					<use xlink:href="#svg-icon-arrow-left2"></use>
				</svg>
			</span>
		</button>

		<div
			id="scroll-mouse-icon"
			class="scroll-mouse svg-icon"
			aria-hidden="true"
		>
			<svg role="img" aria-hidden="true" width="30" height="30">
				<use xlink:href="#svg-icon-swipe"></use>
			</svg>
		</div>
	</section>
</template>

<script>
export default {
	mounted() {
		window.addEventListener("preloader:hide", () => {
			this.$el.classList.remove("opacity-0");
		});
	},
};
</script>