import * as THREE from 'three'
import curveVectors from '../data/curve-vectors.json'

// Преобразовать вектора в THREEE.Vector3 вектора для построения спирали
const curveVectors3 = curveVectors.map(point => new THREE.Vector3(...point));

// Спираль
const curve = new THREE.CatmullRomCurve3(curveVectors3, false, 'catmullrom', 0.7);
const curveLength = curve.getLength();

/**
 * Вспомогательные функции
 * 
 */

// Нахождение фактической длины от начала кривой
function getLengthFromStartAt(value) {
	return curveLength * value;
}

// Нахождение прогресса от начала кривой
function getProgressFromStart(value) {
	return value / curveLength / 1;
}

export { curve, curveLength, getLengthFromStartAt, getProgressFromStart };