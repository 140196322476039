<template>
	<section id="section-contact" class="section contact">
		<a class="section__logo svg-icon" href="/" aria-label="Egorov Agency">
			<svg role="img" aria-hidden="true" width="60" height="58">
				<use xlink:href="#svg-ea-logo"></use>
			</svg>
		</a>

		<div class="section__content">
			<div class="section__title section__title_contact">
				<h2>{{ $t("section_contact.title") }}</h2>
			</div>

			<a
				class="btn btn_red btn_default btn_rectangle btn_with-arrow"
				:href="
					$i18n.getLocale() === 'en'
						? 'https://webar.egorovagency.com/#contact-us'
						: 'https://webar.egorovagency.by/#contact-us'
				"
				target="_blank"
				rel="nofollow noopener"
			>
				<span class="btn__text">{{
					$t("section_contact.button")
				}}</span>
				<span class="btn__icon svg-icon" aria-hidden="true">
					<svg role="img" aria-hidden="true" width="64" height="18">
						<use xlink:href="#svg-icon-arrow-left2"></use>
					</svg>
				</span>
			</a>
		</div>

		<button
			id="scroll-to-section-start"
			class="svg-icon scroll-to-top"
			type="button"
			aria-label="To start"
		>
			<svg role="img" aria-hidden="true" width="64" height="18">
				<use xlink:href="#svg-icon-arrow-left2"></use>
			</svg>
		</button>

		<div class="section__copy custom-font">
			2020 - {{ new Date().getFullYear() }} &copy; Egorov Agency
		</div>
	</section>
</template>