<template>
	<div id="slider-controls" class="slider-controls">
		<button
			id="slider-prev"
			class="slider-controls__button slider-controls__button_prev svg-icon"
			aria-label="Previous"
			type="button"
			:disabled="activeSlide === 1"
			@click="goToSlide(activeSlide - 1)"
		>
			<svg role="img" aria-hidden="true" width="64" height="18">
				<use xlink:href="#svg-icon-arrow-left2"></use>
			</svg>
		</button>
		<div id="slider-pagination" class="slider-controls__pagination">
			<div
				class="slider-controls__bullet"
				v-for="(bullet, i) in bullets"
				:key="i"
				:title="bullet.title"
				:class="{ active: bullet.isActive }"
				@click="goToSlide(bullet.number)"
			></div>
		</div>
		<div
			id="slider-pagination-mobile"
			class="slider-controls__pagination-mobile custom-font"
		>
			<span>{{ this.activeSlide }} / {{ this.slidesAmount }}</span>
		</div>
		<button
			id="slider-next"
			class="slider-controls__button slider-controls__button_next svg-icon"
			aria-label="Next"
			type="button"
			:disabled="activeSlide === slidesAmount"
			@click="goToSlide(activeSlide + 1)"
		>
			<svg role="img" aria-hidden="true" width="64" height="18">
				<use xlink:href="#svg-icon-arrow-left2"></use>
			</svg>
		</button>
	</div>
</template>

<script>
import backgroundTitles from "../data/background-titles.json";

export default {
	mounted() {
		window.addEventListener("slider:slideNumberChanged", (e) => {
			this.activeSlide = e.detail.number;
			history.replaceState(
				null,
				null,
				`#exhibit-desc-${e.detail.number}`
			);
		});

		// Навигация по хешу после загрузки сайта
		window.addEventListener("preloader:hidden", () => {
			if (!location.hash) return;
			const hash = location.hash;
			const number = Number(/(\d+)/gm.exec(hash)[0]);
			if (typeof number === "number" && !window.scrollAnimation) {
				window.dispatchEvent(
					new CustomEvent("slider:slideChange", {
						detail: { number },
					})
				);
			}
		});
	},

	data() {
		return {
			activeSlide: 1,
			slidesAmount: backgroundTitles.length,
		};
	},
	computed: {
		bullets: function () {
			return backgroundTitles.map((title, id) => {
				return {
					id,
					number: id + 1,
					title,
					isActive: this.activeSlide === id + 1,
				};
			});
		},
	},
	methods: {
		goToSlide(number) {
			window.dispatchEvent(
				new CustomEvent("slider:slideChange", { detail: { number } })
			);
		},
	},
};
</script>