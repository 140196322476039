<template>
	<teleport to="body">
		<div id="video-modal" class="video-block" v-bind:class="{ opened }">
			<div :class="`video-block__${playerType}-player`">
				<iframe
					ref="iframe"
					class="video-block__video"
					width="100%"
					height="100%"
					frameborder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowfullscreen
					allowtransparency="true"
					:src="actualVideo"
					v-if="isIframePrepared"
				></iframe>
			</div>

			<div class="video-block__close">
				<button
					@click="$emit('update:opened', false)"
					class="btn btn_white btn_icon svg-icon btn_close js-close-video"
					type="button"
					aria-label="Close video"
				>
					<svg role="img" aria-hidden="true" width="26" height="26">
						<use xlink:href="#svg-icon-close"></use>
					</svg>
				</button>
			</div>
		</div>
	</teleport>
</template>

<script>
export default {
	props: {
		videos: Array,
		opened: Boolean,
	},
	data() {
		return {
			isIframePrepared: false,
			actualVideo: this.videos[0].src,
			playerType: this.videos[0].type,
		};
	},
	watch: {
		opened: function (newVal) {
			this.$emit("update:opened", newVal);
			if (newVal) {
				this.openVideo();
			} else {
				this.closeVideo();
			}
		},
	},
	mounted() {
		window.addEventListener("slider:slideNumberChanged", (e) => {
			this.actualVideo = this.videos[e.detail.number - 1].src;
			this.playerType = this.videos[e.detail.number - 1].type;
		});
	},
	methods: {
		prepareIframe() {
			this.isIframePrepared = true;
		},
		openVideo() {
			if (this.isIframePrepared === false) this.prepareIframe();
			document.body.classList.add("modal-opened");

			setTimeout(() => {
				window.dispatchEvent(new CustomEvent("videoModal:opened"));
			}, 500);
		},
		closeVideo() {
			document.body.classList.remove("modal-opened");
			window.dispatchEvent(new CustomEvent("videoModal:close"));
			// setTimeout(() => {
			// 	this.$refs.video.pause();
			// 	this.$refs.video.currentTime = 0;
			// }, 500);
			setTimeout(() => {
				this.isIframePrepared = false;
			}, 500);
		},
	},
};
</script>

<style lang="scss" scoped>
.video-block__close {
	z-index: 10;
}

.video-block {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	transition: opacity ease-in-out 0.5s;
	transform: translateZ(0);
	pointer-events: none;
	opacity: 0;

	&.opened {
		z-index: 100;
		pointer-events: all;
		opacity: 1 !important;

		.video-block__vertical-player {
			iframe {
				background: rgba(0, 0, 0, 0.8);
			}
		}

		iframe {
			pointer-events: none;
		}
	}
	&__close {
		position: absolute;
		z-index: 10;
		top: 10px;
		right: 10px;
	}

	&__horizontal-player {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		overflow: hidden;
		width: 100%;
		height: 100%;
		pointer-events: none !important;

		.video-block__video {
			width: 100%;
			height: 100%;

			// @media (min-width: 1025px) {
			// padding: 0 70px;
			// iframe {
			// pointer-events: none;
			// }
			// }
		}
	}

	&__vertical-player {
		position: absolute;
		// z-index: 0;
		top: 0;
		left: 0;
		// overflow: hidden;
		width: 100vw;
		height: 100vh;

		.video-block__video {
			position: fixed;
			top: 50%;
			left: 50%;
			width: 37.4vh;
			height: 79vh;
			transform: translate(-50%, -50%);
			border-radius: 7vh;
			box-shadow: -15px 50px 100px rgba(0, 0, 0, 0.5);
		}

		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 41vh;
			height: 81vh;
			content: "";
			transform: translate(-50%, -50%);
			background-image: url("../assets/img/phone-mask.svg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: 40vh 80vh;
			pointer-events: none;
		}
	}
}
</style>