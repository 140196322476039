export default class Controls {

	constructor(ScrollTrigger) {
		ScrollTrigger.create({
			trigger: "#section-gallery",
			pin: '#controls',
			end: 'bottom bottom',
			pinSpacing: false,
			// markers: true
		});
	}

}