<template>
	<section id="section-gallery" class="section gallery prerender">
		<div id="scroll-container" class="scroll-container">
			<!-- Background Titles -->
			<div class="backgrounds">
				<div
					v-for="(backgroundTitle, i) in backgroundTitles"
					:id="`exhibit-background-${i + 1}`"
					:key="i"
					class="background"
				>
					<span class="background__title">{{ backgroundTitle }}</span>
				</div>
			</div>

			<!-- Canvas -->
			<div id="canvas-container" class="canvas-container"></div>

			<!-- Descriptions -->
			<div id="descriptions" class="descriptions">
				<div
					id="descriptions-container"
					class="descriptions__container"
				>
					<div
						v-for="i in backgroundTitles.length"
						:key="i"
						:id="`exhibit-desc-${i}`"
						class="desc"
					>
						<div class="desc__content">
							<div class="desc__mobile-close">
								<button
									@click="closeInfo(i)"
									class="
										btn btn_white btn_icon
										svg-icon
										js-close-info
									"
									type="button"
									aria-label="Close information"
								>
									<svg
										role="img"
										aria-hidden="true"
										width="26"
										height="26"
									>
										<use xlink:href="#svg-icon-close"></use>
									</svg>
								</button>
							</div>

							<div class="section__title desc__title">
								<h2>
									{{
										$t(`section_gallery.title_${i}`) ||
										"new"
									}}
								</h2>
							</div>

							<div class="section__description desc__text">
								<p>
									{{
										$t(`section_gallery.desc_${i}`) || "new"
									}}
								</p>
							</div>

							<div class="desc__btn">
								<button
									@click="isVideoModalOpened = true"
									@mouseover="onPlayVideoButtonMouseOver"
									@mouseout="onPlayVideoButtonMouseOut"
									class="
										btn btn_default btn_rectangle btn_play
										js-btn-play-video
									"
									type="button"
									:aria-label="
										$t('section_gallery.button_video')
									"
								>
									<span class="btn__text">{{
										$t("section_gallery.button_video")
									}}</span>
									<span
										class="btn__icon svg-icon"
										aria-hidden="true"
									>
										<svg
											role="img"
											aria-hidden="true"
											width="18"
											height="18"
										>
											<use
												xlink:href="#svg-icon-play"
											></use>
										</svg>
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Controls -->
			<div id="controls" class="controls">
				<MobileHeader
					:videos="videos"
					@openVideo="isVideoModalOpened = true"
					@openInfo="openInfo"
					@playVideoButtonMouseOver="onPlayVideoButtonMouseOver"
				/>
				<ContactButton />
				<Slider />
			</div>
		</div>
	</section>
	<VideoModal
		v-model:videos="videos"
		v-model:opened="isVideoModalOpened"
		ref="videomodal"
	/>
</template>

<script>
import MobileHeader from "@/components/MobileHeader.vue";
import Slider from "@/components/Slider.vue";
import VideoModal from "@/components/VideoModal.vue";
import ContactButton from "@/components/ContactButton.vue";

import backgroundTitles from "../data/background-titles.json";
import videos from "../data/videos.json";

export default {
	components: {
		MobileHeader,
		Slider,
		VideoModal,
		ContactButton,
	},
	data() {
		return {
			isVideoModalOpened: false,
			backgroundTitles,
			videos,
		};
	},
	methods: {
		openInfo(index) {
			document.body.classList.add("modal-opened");
			document
				.getElementById(`exhibit-desc-${index}`)
				.classList.add("opened-info");
		},
		closeInfo(index) {
			document.body.classList.remove("modal-opened");
			document
				.getElementById(`exhibit-desc-${index}`)
				.classList.remove("opened-info");
		},
		onPlayVideoButtonMouseOver() {
			this.$refs.videomodal.prepareIframe();
		},
	},
};
</script>

<style scoped>
#section-gallery.prerender,
#section-gallery.prerender * {
	overflow: visible;
}

#section-gallery.prerender #canvas-container {
	transform: translateY(-100vh);
	opacity: 0.001;
}

.backgrounds {
	position: fixed;
	top: 200vh;
	width: 100vw;
}
</style>