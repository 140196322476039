import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

import ScrollTo from './scroller-modules/scroll-to.js';
import Slider from './scroller-modules/slider.js';
import CanvasContainer from './scroller-modules/canvas-container';
import Controls from './scroller-modules/controls';
import Backgrounds from './scroller-modules/exhibit-backgrounds';
import BackgroundColor from './scroller-modules/background-color';
import SectionStart from './scroller-modules/section-start';
import SectionGallery from './scroller-modules/section-gallery';
import SectionContact from './scroller-modules/section-contact.js';
import CameraAnimation from './scroller-modules/camera-animation.js';

/**
 * Глобальный флаг анимаци скролла.
 * Необходим для определения занятости скролла,
 * во избежание конфликтов анимации
 */
window.scrollAnimation = false;

export default class Scroller {
	constructor({ scene, camera, devInfo }) {

		const scrollContainer = document.getElementById('scroll-container');

		// Основной таймлайн
		const TIMELINE = gsap.timeline({
			scrollTrigger: {
				trigger: '#scroll-container',
				start: 'top top',
				end: 'bottom 100%',
				scrub: 1.5,
			}
		});

		const scrollTo = new ScrollTo(scrollContainer, gsap);			// Скролл к указанной позиции на таймлайне и на странице
		const slider = new Slider(scrollTo);							// Взаимодействие со слайдером
		new CanvasContainer(ScrollTrigger)								// Поведение контейнера канваса
		new Controls(ScrollTrigger)										// Поведение элементов управления слайдера + мобильная панель с кнопками
		new Backgrounds(scrollContainer, gsap)							// Поведение фонов для экпонатов
		new BackgroundColor(gsap)										// Поведение общего фона
		new SectionStart(ScrollTrigger, scrollTo)						// Поведение секции "start"
		new SectionGallery(TIMELINE, ScrollTrigger, scrollTo)			// Поведение секции "gallery"
		new SectionContact(ScrollTrigger, scrollTo)						// Поведение секции "contact"
		new CameraAnimation({ scene, camera, TIMELINE, slider, devInfo })	// Анимация камеры
	
	
	}
}