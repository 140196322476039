import { keystates, lookAtKeyStates } from './keystates';

export default class SectionGallery {

	constructor(TIMELINE, ScrollTrigger, scrollTo) {

		ScrollTrigger.create({
			trigger: '#scroll-container',
			start: "top top",
			end: `bottom bottom`,
			pin: '#descriptions',
			// markers: true
		});

		// Анимация переходов между описаниями 
		for (let i = 0; i <= keystates.length - 2; i++) {
			if (keystates[i].type === 'lookAt' && i != 0) {

				TIMELINE
					.to(`#exhibit-desc-${keystates[i].exhibitId}`, {
						y: '+=60vh',
						opacity: -1,
						ease: "power4.easeOut",
						duration: keystates[i + 1].progress - keystates[i].progress,
					}, keystates[i].progress);

				if (keystates[i].exhibitId < lookAtKeyStates.length)
					TIMELINE
						.fromTo(`#exhibit-desc-${keystates[i].exhibitId + 1}`,
							{
								y: '-=10vh',
								opacity: 0,
							},
							{
								y: '0vh',
								opacity: 1,
								ease: "power4.easeOut",
								duration: keystates[i + 1].progress - keystates[i].progress,
							}, keystates[i].progress);

			}
		}

		for (let i = 0; i < keystates.length - 1; i++) {
			if (keystates[i].type === 'lookAt' && i !== 0) {

				// Анимация перемещения контейнера описаний
				TIMELINE
					.to('#descriptions-container', {
						yoyo: true,
						y: `-=${100}vh`,
						ease: "power2.out",
						duration: keystates[i + 1].progress - keystates[i].progress,
					}, keystates[i].progress);

				// ПЕРЕХОД к следующему экспонату
				TIMELINE
					.to(window, {
						yoyo: true,
						duration: keystates[i + 1].progressFrom - keystates[i].progress,
						onStart: () => {
							scrollTo.goToProgress(keystates[i + 1].progressFrom);
						},
					}, keystates[i].progress);
			}

			// ПЕРЕХОД к предыдущему экспонату
			if (keystates[i].type === 'transition') {
				TIMELINE
					.to(window, {
						yoyo: true,
						duration: keystates[i + 1].progress - keystates[i].progressFrom,
						onReverseComplete: () => {
							scrollTo.goToProgress(keystates[i - 2].progressFrom);
						},
					}, keystates[i].progress);
			}
		}

	}

}
